import {ReactElement, RefObject, ReactNode, useRef} from 'react';

import type {NextPage} from 'next';
import type {AppProps} from 'next/app';
import Head from 'next/head';
import Router from 'next/router';

import nProgress from 'nprogress';
import 'nprogress/nprogress.css';

import ThemeProvider from 'src/theme/ThemeProvider';
import CssBaseline from '@mui/material/CssBaseline';

import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';

import {CacheProvider, EmotionCache} from '@emotion/react';
import createEmotionCache from 'src/createEmotionCache';

import {appWithTranslation} from 'next-i18next';

import {SidebarProvider} from 'src/contexts/SidebarContext';

import {Provider as ReduxProvider} from 'react-redux';
import {store} from 'src/redux/store';

import useScrollTop from 'src/hooks/useScrollTop';

import {SnackbarProvider, SnackbarProviderProps} from 'notistack';
import HttpNotification from '@/components/Utilities/HttpNotification';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import '../src/i18n/i18n';

import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';

const clientSideEmotionCache = createEmotionCache();

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

interface IMyAppProps extends AppProps {
  emotionCache?: EmotionCache;
  Component: NextPageWithLayout;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, //process.env.NODE_ENV === 'production',
      retry: false,
    },
  },
});

const snackBarProps = (notistackRef: RefObject<SnackbarProvider>): SnackbarProviderProps => {
  return {
    ref: notistackRef,
    dense: true,
    preventDuplicate: true,
    maxSnack: 6,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
    action: (key) => (
      <IconButton onClick={() => notistackRef.current?.closeSnackbar(key)}>
        <CloseIcon sx={{color: '#fff'}} />
      </IconButton>
    ),
  };
};

function MyApp(props: IMyAppProps) {
  const {Component, emotionCache = clientSideEmotionCache, pageProps} = props;
  const getLayout = Component.getLayout ?? ((page) => page);

  useScrollTop();

  const notistackRef = useRef<SnackbarProvider>(null);

  Router.events.on('routeChangeStart', nProgress.start);
  Router.events.on('routeChangeError', nProgress.done);
  Router.events.on('routeChangeComplete', nProgress.done);

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>Mr Glass Manufacturing</title>
        <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no' />
      </Head>
      <SnackbarProvider {...snackBarProps(notistackRef)}>
        <ReduxProvider store={store}>
          <SidebarProvider>
            <ThemeProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <QueryClientProvider client={queryClient}>
                  <CssBaseline />
                  <HttpNotification />
                  {getLayout(<Component {...pageProps} />)}
                  <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
              </LocalizationProvider>
            </ThemeProvider>
          </SidebarProvider>
        </ReduxProvider>
      </SnackbarProvider>
    </CacheProvider>
  );
}

export default appWithTranslation(MyApp);
