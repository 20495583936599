import {Fragment} from 'react';

import {useSnackbar} from 'notistack';

import Button from '@mui/material/Button';

import {axiosInstance} from '@/services/httpService';

const HttpNotification = () => {
  const {enqueueSnackbar} = useSnackbar();

  // Response
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.message === 'Network Error') {
        const action = () => (
          <Button sx={{color: '#fff'}} onClick={() => window.location.reload()}>
            Reload
          </Button>
        );

        enqueueSnackbar('The connection to the server is lost.', {variant: 'error', action});
        return Promise.reject(error);
      }

      if (error.response.status === 401) {
        enqueueSnackbar('You are unauthorized to access this resource.', {variant: 'error'});

        return Promise.reject(error);
      }

      let message: string | undefined;

      if (error.response?.data !== undefined) {
        message = error.response?.data.errorMessage;
      } else {
        message = error.message;
      }

      enqueueSnackbar(message ?? 'There was an unexpected error loading the data.', {variant: 'error'});

      return Promise.reject(error);
    }
  );

  return <Fragment></Fragment>;
};

export default HttpNotification;
