import axios, {AxiosHeaders, AxiosResponse, RawAxiosRequestConfig} from 'axios';

import {enqueueSnackbar} from 'notistack';

import tokenService from './tokenService';

const unAuthorizedStatus = [401];
const nonValidatedApi = ['/v1/Auth/Login', '/v1/Users/ForgotPassword'];
const nonValidatedRoutes = ['/auth/login', '/auth/forgot-password'];

const {getAccessToken} = tokenService();

function validateRouteCheck(route: string): boolean {
  let validationToggle = false;

  const routeCheck = nonValidatedRoutes.find((x) => x === route);

  if (routeCheck) validationToggle = true;

  return validationToggle;
}

async function handleError(error: any) {
  if (error.message === 'Network Error') {
    enqueueSnackbar('Unable to connect to the Server.', {variant: 'error'});

    return Promise.reject(error);
  }

  if (error.response?.status === 401) {
    error.response.data !== undefined
      ? enqueueSnackbar(error.response.data.errorMessage, {variant: 'error'})
      : enqueueSnackbar('You are unauthorized to access this resource.', {variant: 'error'});

    return Promise.reject(error);
  }

  let message: string | undefined;

  if (error.response?.data !== undefined) {
    message = error.response?.data.errorMessage;
  } else {
    message = error.message;
  }

  enqueueSnackbar(message ?? 'There was an unexpected error loading the data.', {variant: 'error'});

  return Promise.reject(error);
}

export const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
});

// Request
axiosInstance.interceptors.request.use(
  async (config) => {
    config.paramsSerializer = {
      indexes: true, // 'a[0]=b&a[1]=c'
    };

    const accessToken = await getAccessToken();

    if (accessToken !== null) {
      if (config.url !== '/auth/login') {
        (config.headers as AxiosHeaders).set('Authorization', `Bearer ${accessToken.token}`);
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//Response
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const status = error.response?.status;

    if (!status) return await handleError(error);

    if (!validateRouteCheck(window.location.pathname)) {
      if (unAuthorizedStatus.includes(status)) {
        await getAccessToken();
      }
    }

    return await handleError(error);
  }
);

function validateApiCheck(url: string): boolean {
  let validationToggle = false;

  const urlCheck = nonValidatedApi.find((x) => x.toLowerCase() === url.toLowerCase());

  if (urlCheck) validationToggle = true;

  return validationToggle;
}

abstract class HttpService {
  abstract url: string;

  protected async getBase<T>(url: string, config: RawAxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
    return await axiosInstance.get<T>(url, await this.getConfig(url, config));
  }

  protected async postBase<T>(url: string, data: any, config: RawAxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
    return await axiosInstance.post<T>(url, data, await this.getConfig(url, config));
  }

  protected async putBase<T>(url: string, data: any, config: RawAxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
    return await axiosInstance.put<T>(url, data, await this.getConfig(url, config));
  }

  protected async deleteBase<T>(url: string, config: RawAxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
    return await axiosInstance.delete<T>(url, await this.getConfig(url, config));
  }

  private async getHeaders(url: string) {
    if (validateApiCheck(url)) return {};

    const accessToken = await getAccessToken();

    return {
      Authorization: `Bearer ${accessToken}`,
    };
  }

  private async getConfig(url: string, config: RawAxiosRequestConfig = {}) {
    return {
      headers: await this.getHeaders(url),
      paramsSerializer: {
        indexes: true, // 'a[0]=b&a[1]=c'
      },
      ...config,
    };
  }
}

export default HttpService;
