import {useState, ReactNode, createContext} from 'react';
type SidebarContext = {
  sidebarToggle: any;
  toggleSidebar: () => void;
  closeSidebar: () => void;
};

export const SidebarContext = createContext<SidebarContext>({} as SidebarContext);

type Props = {
  children: ReactNode;
};

export function SidebarProvider({children}: Props) {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const closeSidebar = () => {
    setSidebarToggle(false);
  };

  return <SidebarContext.Provider value={{sidebarToggle, toggleSidebar, closeSidebar}}>{children}</SidebarContext.Provider>;
}
