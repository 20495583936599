import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {jwtDecode} from 'jwt-decode';

import {enqueueSnackbar} from 'notistack';

import configs from '@/configs/auth';
import {ILoginResponse} from '@/services/http/AuthService';

export interface IPermission {
  resource: string;
  action: string;
  value: string;
}

export interface IRefreshToken {
  expiration: string;
  token: string;
}

export interface IAccessToken {
  expiration: string;
  token: string;
  decodedToken: IDecodedAccessToken;
}

export interface IDecodedAccessToken {
  jti: string;
  userId: string;
  email: string;
  firstName: string;
  lastName: string;
  role: Array<string>; // sometimes is a string (when suer has only one role)
  nbf: number;
  exp: number;
  iat: number;
  iss: string;
  aud: string;
  customerId: number;
}

interface IAuthState {
  accessToken: IAccessToken;
  refreshToken: IRefreshToken;
  permissions: Array<IPermission>;
  isAuthenticated: boolean;
  isInitialized: boolean;
}

const initialState: IAuthState = {
  accessToken: {
    expiration: '',
    token: '',
    decodedToken: {
      jti: '',
      userId: '',
      firstName: '',
      lastName: '',
      email: '',
      role: [],
      nbf: 0,
      exp: 0,
      iat: 0,
      iss: '',
      aud: '',
      customerId: 0,
    },
  },
  refreshToken: {
    expiration: '',
    token: '',
  },
  permissions: [],
  isAuthenticated: false,
  isInitialized: false,
};

const setTokens = (accessToken: IAccessToken, refreshToken: IRefreshToken, permission: Array<IPermission>): void => {
  if (typeof window !== 'undefined') {
    localStorage.setItem(configs.accessTokenStorageKey, JSON.stringify(accessToken));
    localStorage.setItem(configs.refreshTokenStorageKey, JSON.stringify(refreshToken));
    localStorage.setItem(configs.permissionsStorageKey, JSON.stringify(permission));
  }
};

const clearTokens = (): void => {
  if (typeof window !== 'undefined') {
    localStorage.removeItem(configs.accessTokenStorageKey);
    localStorage.removeItem(configs.refreshTokenStorageKey);
    localStorage.removeItem(configs.permissionsStorageKey);
  }
};

const getInitialState = (): IAuthState => {
  if (typeof window === 'undefined') {
    return initialState;
  }

  const accessToken = localStorage.getItem(configs.accessTokenStorageKey);
  const refreshToken = localStorage.getItem(configs.refreshTokenStorageKey);
  const permissions = localStorage.getItem(configs.permissionsStorageKey);

  if (accessToken === undefined || accessToken === null || accessToken === '') {
    return initialState;
  }

  if (refreshToken === undefined || refreshToken === null || refreshToken === '') {
    return initialState;
  }

  if (permissions === undefined || permissions === null || permissions === '') {
    return initialState;
  }

  const parsedAccessToken = JSON.parse(accessToken) as IAccessToken;
  const parsedRefreshToken = JSON.parse(refreshToken) as IRefreshToken;
  const parsedPermissions = JSON.parse(permissions) as Array<IPermission>;

  if (parsedAccessToken.token === '' || parsedAccessToken.expiration === '') {
    clearTokens();
    return initialState;
  }

  if (parsedRefreshToken.token === '' || parsedRefreshToken.expiration === '') {
    clearTokens();
    return initialState;
  }

  if (permissions.length === 0) {
    clearTokens();
    return initialState;
  }

  return {
    accessToken: parsedAccessToken,
    refreshToken: parsedRefreshToken,
    permissions: parsedPermissions,
    isAuthenticated: true,
    isInitialized: true,
  };
};

const transformAccessToken = (token: string, expiration: string): IDecodedAccessToken => {
  if (token === '' || expiration === '') {
    return initialState.accessToken.decodedToken;
  }

  const decodedToken = jwtDecode<IDecodedAccessToken>(token);

  // There is zero role
  if (decodedToken.role === undefined) {
    decodedToken.role = [];
  }

  // There is only one role
  if (!Array.isArray(decodedToken.role)) {
    decodedToken.role = [decodedToken.role];
  }

  return decodedToken;
};

const parsePermission = (permissions: Array<string>): Array<IPermission> => {
  return permissions.map((permission) => {
    return {
      value: permission,
      resource: permission.split('.')[1],
      action: permission.split('.')[2],
    };
  });
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: getInitialState(),
  reducers: {
    addTokens: (state, action: PayloadAction<ILoginResponse & {showMessage?: boolean}>) => {
      state.accessToken.token = action.payload.accessToken.token;
      state.accessToken.expiration = action.payload.accessToken.expiration;
      state.accessToken.decodedToken = transformAccessToken(action.payload.accessToken.token, action.payload.accessToken.expiration);

      state.refreshToken.token = action.payload.refreshToken.token;
      state.refreshToken.expiration = action.payload.refreshToken.expiration;

      state.permissions = parsePermission(action.payload.permissions);

      state.isAuthenticated = true;

      setTokens(state.accessToken, state.refreshToken, state.permissions);

      if ((action.payload.showMessage === undefined || action.payload.showMessage) && process.env.NEXT_PUBLIC_ENV !== 'cypress') {
        enqueueSnackbar({message: 'Token have been successfully added.', variant: 'success'});
      }
    },
    removeTokens: (state) => {
      state.accessToken.expiration = initialState.accessToken.expiration;
      state.accessToken.token = initialState.accessToken.token;
      state.accessToken.decodedToken = initialState.accessToken.decodedToken;

      state.refreshToken.expiration = initialState.refreshToken.expiration;
      state.refreshToken.token = initialState.refreshToken.token;

      state.permissions = initialState.permissions;

      state.isAuthenticated = initialState.isAuthenticated;
      state.isInitialized = initialState.isInitialized;

      clearTokens();

      if (process.env.NEXT_PUBLIC_ENV !== 'cypress') {
        enqueueSnackbar({message: 'You have successfully signed out.', variant: 'success'});
      }
    },
  },
});

export const {addTokens, removeTokens} = authSlice.actions;

export default authSlice.reducer;
