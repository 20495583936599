import axios from 'axios';

import {store} from '@/redux/store';

import {jwtDecode} from 'jwt-decode';

import configs from '@/configs/auth';

import {IAccessToken, IRefreshToken, addTokens, removeTokens, IDecodedAccessToken} from '@/redux/slices/authSlice';
import {ILoginResponse, IToken} from './http/AuthService';

const tokenService = () => {
  let isRefreshRequesting = false;

  const getNewToken = (accessToken: IAccessToken): Promise<IToken> => {
    const baseApiUrl = process.env.NEXT_PUBLIC_API_URL;
    const refreshTokenUrl = '/v1/auth/refreshToken';

    return new Promise<IToken>(async (resolve, reject) => {
      const refreshToken = getRefreshToken();

      axios
        .post<ILoginResponse>(baseApiUrl + refreshTokenUrl, {
          accessToken: accessToken?.token,
          refreshToken: refreshToken?.token,
        })
        .then((response) => {
          store.dispatch(addTokens(response.data));
          resolve(response.data.accessToken);
        })
        .catch((error) => {
          store.dispatch(removeTokens());
          window.location.href = '/';
          reject(error);
        })
        .finally(() => {
          isRefreshRequesting = false;
        });
    });
  };

  const waitForNewToken = (token: string, maxTry = 5, currentTry = 0): Promise<IToken> => {
    return new Promise<IToken>((resolve, reject) => {
      const handler = setInterval(() => {
        const accessToken = getAccessTokenInternal();
        if (accessToken !== null && accessToken.token !== token) {
          resolve(accessToken);
        }

        currentTry++;

        if (currentTry >= maxTry) {
          clearInterval(handler);
          reject(null);
        }
      }, 1000);
    });
  };

  const getAccessTokenInternal = (): IToken | null => {
    if (typeof window === 'undefined') {
      return null;
    }

    const item = localStorage.getItem(configs.accessTokenStorageKey);

    if (item === null) {
      return null;
    }

    const parsedToken = JSON.parse(item) as IAccessToken;

    return {token: parsedToken.token, expiration: parsedToken.expiration};
  };

  const isTokenExpired = (expiration: string): boolean => {
    const expDate = new Date(expiration);
    const currentDate = new Date();

    return currentDate > expDate;
  };

  const getAccessToken = async (): Promise<IAccessToken | null> => {
    if (typeof window === 'undefined') {
      return null;
    }

    const item = localStorage.getItem(configs.accessTokenStorageKey);

    if (item === null) {
      return null;
    }

    const accessToken = JSON.parse(item) as IAccessToken;

    const isExpired = isTokenExpired(accessToken.expiration);

    if (isExpired && !isRefreshRequesting) {
      isRefreshRequesting = true;

      const newToken = await getNewToken(accessToken);
      accessToken.token = newToken.token;
      accessToken.expiration = newToken.expiration;
      accessToken.decodedToken = jwtDecode<IDecodedAccessToken>(newToken.token);

      isRefreshRequesting = false;
    } else if (isRefreshRequesting) {
      const newToken = await waitForNewToken(accessToken.token);
      accessToken.token = newToken.token;
      accessToken.expiration = newToken.expiration;
      accessToken.decodedToken = jwtDecode<IDecodedAccessToken>(newToken.token);
    }

    return accessToken;
  };

  const getRefreshToken = (): IRefreshToken | null => {
    if (typeof window === 'undefined') {
      return null;
    }

    const item = localStorage.getItem(configs.refreshTokenStorageKey);

    return item !== null ? JSON.parse(item) : null;
  };

  return {getAccessToken};
};

export default tokenService;
