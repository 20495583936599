import React, {useState, createContext, useEffect} from 'react';
import {ThemeProvider} from '@mui/material';
import {themeCreator} from './base';
import {StylesProvider} from '@mui/styles';

export const ThemeContext = createContext((_themeName: string): void => {});

interface IThemeProviderProps {
  children: React.ReactNode;
}

const ThemeProviderWrapper: React.FC<IThemeProviderProps> = (props: any) => {
  const [themeName, _setThemeName] = useState('GreyGooseTheme');

  useEffect(() => {
    const curThemeName = window.localStorage.getItem('appTheme') || 'GreyGooseTheme';
    _setThemeName(curThemeName);
  }, []);

  const theme = themeCreator(themeName);
  const saveThemeName = (themeName: string): void => {
    window.localStorage.setItem('appTheme', themeName);
    _setThemeName(themeName);
  };

  return (
    <StylesProvider injectFirst>
      <ThemeContext.Provider value={saveThemeName}>
        <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
      </ThemeContext.Provider>
    </StylesProvider>
  );
};

export default ThemeProviderWrapper;
