const esJSON = {
  Reports: 'Informes',
  Export: 'Exportar',
  General: 'General',
  Calendar: 'Calendario',
  Dashboards: 'Cuadros de mando',
  Applications: 'Aplicaciones',
  Management: 'Administración',
  Users: 'Usuarios',
  Quotes: 'Estimados',
  Orders: 'Ordenes',
  monthlyOrders: 'Ordenes Mensuales',
  monthlyQuotes: 'Estimados Mensuales',
  Customers: 'Clientes',
  dailyOrders: 'Ordenes Diarias',
  dailyQuotes: 'Estimados Diarios',
  'List All': 'Listar todo',
  'User Profile': 'Perfil del usuario',
  Login: 'Acceso',
  Status: 'Estado',
  Welcome: 'Bienvenida',
  Notifications: 'Notificaciones',
  Production: 'Producción',
  Inventory: 'Inventario',

};

export default esJSON;
